.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveIn {
  from {bottom : -200px; object-fit : fill; height : 50px}
  to {bottom : 0; object-fit : fill; height : 380px}
}

@keyframes bounce {
  0% {object-fit : fill; height : 421px}
  50% {object-fit : fill; height : 435px}
  100% {object-fit : fill; height : 421px}
}

@keyframes slowlyMovingRightIllusion {
  from {width : 100vw}
  to {width : 125vw;}
}


@keyframes movingRight {
  from {left : -5vw}
  to {left : 100vw;}
}

@keyframes movingLeft {
  from {left : 100vw}
  to {left : -5vw;}
}

@keyframes floating1 {
  0% {top : 0}
  50% {top : 1.5vw;}
  100% {top : 0vw;}
}

@keyframes floating2 {
  0% {top : 8vw}
  50% {top : 7vw;}
  100% {top : 8vw;}
}

@keyframes floating3 {
  0% {top : 15vw}
  50% {top : 16.5vw;}
  100% {top : 15vw;}
}